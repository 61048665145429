
#bg {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  height: 100vh;
  background: 
  linear-gradient(180deg,  rgba(0, 0, 0, 0.847),rgba(0, 0, 0, 0.314)), 
  url('https://cdn.dribbble.com/users/24871/screenshots/5743824/attachments/1240048/workspace2019.png?resize=800x600&vertical=center');
  background-position:center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-clip: content-box;
  transition: 0.3s all;
}

.litebtn{
  background: white;
  color: black;
}

#mac{
  animation: color-change 50s infinite;
}

@keyframes color-change {
  0% { color: red; }
  10% { color: rgb(255, 119, 0); }
  20% { color: rgb(255, 208, 0); }
  30% { color: rgb(183, 255, 0); }
  40% { color: rgb(0, 255, 21); }
  50% { color: rgb(0, 255, 225); }
  60% { color: rgb(8, 0, 255); }
  70% { color: rgb(149, 0, 255); }
  80% { color: rgb(255, 0, 140); }
  90% { color: rgb(0, 255, 247); }
  100% { color: red; }
}