
// Defaults 
$heading: 'Space Mono', monospace;
$content: 'Poppins', sans-serif;

$grey: rgba(100, 100, 100, 0.794);
$blue: black;

#nav-wrap{
    background: #9898F8;
}

::selection{
    background: #9898F8;
}

h1,h2,h3,h4,h5,h6{
    font-family: $heading;
    font-weight: bold;
    color: black;
}

p{
    font-weight: 500;
}

#navbar-center a{  
    font-weight: 500;
    font-family: $content !important;
    color: #E2E1FC!important;
}

button{
    font-size: medium;
    cursor: pointer;
    border-radius: 0.5em;
    background: $blue;
    color: white;
    font-family: $content;
    border: none;
    padding: 1em;
    white-space: nowrap;
    font-weight: 500;
}

button.lite{
    text-transform: uppercase;
    background: none;
    color:$grey;
    border: 1px $grey solid;
    padding: 0.7em 1em;
    cursor: auto;
}

@media (max-width:780px) {
    #navbar-center{
        display: none !important;
    }

    .uk-navbar-container{
        padding-bottom: 10% !important;
    }
}

.primary{
    color: #fff;
    background: #8585F7;
    border-color: #8585F7;
    display: inline-flex; 
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

/*  Navbar  */

a#brand{
    font-family: $heading !important;
    font-size: 3em;
    text-transform: lowercase;
    color: #fff !important;
    font-weight: 600;
}

#navbar-center{
    width: 100%;
    padding-right: 4%;
    display: inline-flex;
    justify-content: end;
}

/* landing */

#l-div h1{
    color: white !important;
}

#l-div h4{
    color: white;
}

@media (max-width:800px) {
    #l-div h1{
        font-size: 15vw !important;
    }
    #l-div h4{
        font-size: 3vw !important;
    }
}

#land-left{
    text-align: left;
}

#land-left h1{
    letter-spacing: -3px;
    font-size: 2.5em;
}

// Service

.service-card{
    border-radius: 15px;
    background: #8080800a;
    // background: black;
    padding: 0.5% 10%;
}

// Card

.card #title, .card #type{
    color: #8585F7;
    transition: ease-in-out 0.7s;
}

.card, .card button{
    color: white;
    transition: ease-in-out 0.7s;
}

.card:hover #title, .card:hover #type{
    color: white;
}

.card:hover{
    color: black !important;
    background: #8585F7;
}

.card:hover img{
    opacity: 1 !important;
    box-shadow: 1px 1px 10px 10px #6371A1;
}

#card-btn{
    background: white;
    transition: all 0.5s;
    right: 0;
    bottom: -70px;
    position: absolute;
    cursor:pointer;
    display: inline-flex; 
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

#card-btn span{
    transform: rotate(45deg);
    transition: all 0.5s;
}

.card:hover #card-btn span{
    transform: rotate(-45deg);
}

.card:hover #card-btn{
    bottom: 0 !important;
    @extend .primary;
    background: #08081A;
}


@media (max-width: 960px) {
    #down-arrow{
        display: none;
    }
    .uk-navbar-right{
        display: none;
    }
}

// Testimonial 

#swiper-wrapper .swiper {
    width: 80%;
    height: 40vh;
    padding: 0% 0% 0% 0%;
}

.swiper-pagination{
    right: -80px !important;
}

input, textarea{
    border: none;
    border-bottom: rgba(128, 128, 128, 0.575) 1px solid;
    padding: 1% 0%;
    margin-bottom: 1%;
    width: 100% !important;
    background: none;
    outline: none !important;
    font-family: $content;
    resize: none;
}

::placeholder{
    font-size: 1em;
}

input:focus, input:active, textarea:focus, textarea:active{
    border-bottom-color: #E0775C;
}

.uk-icon-button{
    background: #6371A1;
    color: black !important;
}

li a{
    color: white !important;
}

li a{
    font-size: medium;
    color: white !important;
}